#page-header.about-header{
    background-image: url("./images/banner.png");
  }

  #page-header{
   
    width: 100%;
    height: 40vh;
    background-size: cover;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 14px;
}
#page-header h2,
#page-header p{
    color: white;
}
#page-header h2{
    font-size: 46px;
    line-height: 54px;
}

#about-head{
    display: flex;
    align-items: center;
  }

  #about-head img{
    width: 50%;
    height: auto;
  }

  #about-head  div{
    padding-left: 40px;
    /* margin-left: 40px; */
  }

  .section-p1 {
    padding: 40px 80px;
  }

  #about-head   h1{
    font-size: 50px;
    line-height: 64px;
    color: #222;
  }

  #about-head div p{
    font-size: 16px;
    color: #465b52;
    margin: 15px 0 20px 0;
  }

  abbr[title] {
    text-decoration: underline dotted;
    font-size: 18px;
   
}

#page-header p {
    font-size: 20px;
    
   
}

#form-details{
  display: flex;
  justify-content: space-between;
  margin: 30px;
  padding: 80px;
  border: 1px solid white;
}

#form-details form{
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#form-details form span{
  font-size: 24px;
}
#form-details form input,
#form-details form textarea{
  width: 100%;
  padding: 12px 15px; 
  outline: none;
  margin-bottom: 20px;
  border: 1px solid #e1e1e1;

}
#form-details form button{
  background-color: green;
  color: white;
}

#form-details .people div{
  padding-bottom: 25px;
  display: flex;
  align-items: flex-start;

}

#form-details .people div img{
  
width: 85px;
height: 85px;
object-fit: cover;  
margin-right: 15px;
border-radius: 15px;
cursor: pointer;
}
#form-details  .people div P{
  margin: 0;
  font-size: 13PX;
  line-height: 20PX;
}
#form-details  .people div P span{

  display: block;
  font-size: 16px;
  font-weight: 600;
  color: black;

}
#form-details h2{
  font-size: 46px;
  line-height: 54px;
  color: #222;
}
.people .agency{
  margin: 20px;
  font-size: 20px;
}

#contact-details {
  display: flex;
 align-items: center;
 justify-content: space-between;
}
#contact-details .details{
  width: 40%;
}
#contact-details .details span{
  font-size: 12px;
}
#contact-details .details h2{
  font-size: 26px;
  line-height: 35px;
  padding: 20px 0;
}
#contact-details .details h3{
  font-size: 16px;
  padding-bottom: 20px;
}
#contact-details .details li{
  list-style: none;
  display: flex;
  padding: 10px 0;
}
#contact-details .details li i{
  font-size: 14px;
  cursor: pointer;
  padding-right: 10px;

}
#contact-details .details li p{
  margin: 0;
  font-size: 14px;
  color: blue;
  cursor: pointer;

}
#contact-details .map{
  width: 55%;
  height: 400px;

}
#contact-details .map iframe{
  width: 100%;
  height: 100%;
}

#form-details{
  display: flex;
  justify-content: space-between;
  margin: 30px;
  padding: 80px;
  border: 1px solid white;
}
.dis{
  display: none;
}



@media (max-width: 520px) {

  #about-head{
    flex-direction: column;
 }

 #about-head img{
    width: 100%;
    margin-bottom: 20px;
 }
 #about-head div{
    padding-left: 0px;
 }

.section-p1{
  padding: 40px 20px;
  background-position: 55%;
}

#form-details{
  margin: 10px;
  padding: 30px 10px;
  flex-wrap: wrap;

}
#form-details form{
  width: 100%;
  margin-bottom: 30px;
}
#about-head div h1{
  font-size: 30px;
}
#form-details form h2{
  font-size: 25px;
}
#contact-details {
  flex-direction: column;
   
}
#contact-details .details {
 width: 100%;
 margin-bottom: 30px;
}
#contact-details .map{
   display: none;
}
.dis{
  display: block;
}
}